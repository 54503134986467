import {
    isProduction,
    logger,
    isAppModeKiosk,
    isAppModePos,
    isTyroEftposClientLoaded, unixDatetimeToDateTime
} from '../shared/utility';
import {mpoSentry} from "./Sentry";
import axiosInstance from "../components/axios";
import mobiscroll from "@mobiscroll/react";
import Cookies from "universal-cookie";

const PAIR = "PAIR";
const TXN = "TXN";
const REFUND = "REFUND";

const pairingCookieName = "tyropair";
const transactionCookieName = "tyrotxn";

export const mpoTyroEftpos = {

    _initted: false,
    _api_key: "",
    _iclient: null,

    _available: false,
    _pos_guid: "",
    _tid: "",
    _mid: "",
    _integration_key: "",
    _paired: false,

    _operator: "",
    _token: "",
    _token_expiry: null,
    _last_session_id: null,
    _last_transaction_ref: null,

    _integrated_receipts: false,
    _integrated_receipts_merchant_copy: false,
    _integrated_surcharging: false,

    _country_code: null,
    _currency_code: null,
    _tender_amount: 0,
    _total_amount: 0,
    _callback: null,
    _txn_callback: null,
    _compare_transaction: null,

    Init: function (pos_guid, api_key, mid, secret, integrated_receipts, integrated_receipts_merchant_copy, integrated_surcharging, country_code, currency_code) {
        logger("TyroEftpos: Init");
        if (this._initted) {
            logger("TyroEftpos: Already initted");
            return;
        }
        if (!isAppModeKiosk() && !isAppModePos()) {
            logger("TyroEftpos: Not in POS or KIOSK mode");
            return;
        }
        if (!isTyroEftposClientLoaded()) {
            logger("TyroEftpos: TYRO is undefined");
            return;
        }
        this._initted = true;
        this._pos_guid = pos_guid;
        this._api_key = api_key;
        this._mid = mid;
        this._integration_key = secret;
        this._integrated_receipts = parseInt(integrated_receipts, 10) === 1;
        this._integrated_receipts_merchant_copy = parseInt(integrated_receipts_merchant_copy, 10) === 1;
        this._integrated_surcharging = parseInt(integrated_surcharging, 10) === 1;
        this._country_code = country_code;
        this._currency_code = currency_code;
        var posProductInfo = {
            posProductVendor: "mypreorder pty ltd",
            posProductName: "mypreorder kiosk",
            posProductVersion: process.env.REACT_APP_APP_VERSION,
            siteReference: this._pos_guid
        };
        /*eslint-disable no-undef*/
        this._iclient = new TYRO.IClientWithUI(this._api_key, posProductInfo);
        /*eslint-enable no-undef*/
        this._available = this._pos_guid !== "";
    },

    isTyroEftposAvailable: function () {
        logger('mpoTyroEftpos.isTyroPaymentsAvailable: '+mpoTyroEftpos._available);
        return mpoTyroEftpos._available;
    },

    isPaired: function () {
        logger('mpoTyroEftpos.isPaired: '+mpoTyroEftpos._paired);
        return mpoTyroEftpos._paired;
    },

    getExponentialBackoffMs: function (retry_num = 1) {
        let ms;
        switch (retry_num) {
            case 1:
                ms = 1000;
                break;
            case 2:
                ms = 2000;
                break;
            case 3:
                ms = 4000;
                break;
            case 4:
                ms = 8000;
                break;
            case 5:
                ms = 16000;
                break;
            default:
                ms = 32000;
        }
        logger('mpoTyroEftpos.getExponentialBackoffMs '+ms+' Retry#'+retry_num)
        return ms;
    },

    updateBackendSettings: function() {
        logger('mpoTyroEftpos.updateSettings called');
        const data = {
            RequestAction: 'UpdateEftpos',
            pos_guid: mpoTyroEftpos._pos_guid,
            payload: {
                username: mpoTyroEftpos._mid,
                paircode: mpoTyroEftpos._tid,
                secret: mpoTyroEftpos._integration_key,
                // oauth_token: mpoTyroEftpos._token,
                // oauth_expiry: unixDatetimeToDateTime(mpoTyroEftpos._token_expiry),
            }
        };
        //attachDeviceInfoToData(data);
        mpoSentry.addInfoBreadcrumbAll('tyro','updateBackendSettings', data);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }

            })
            .catch(error => {
                logger(JSON.stringify(error));
                mobiscroll.toast({message: 'Error EFT1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });
    },

    setCallback: function (src, callback = null) {
        logger('mpoTyroEftpos.setCallback called from '+src+' '+(callback === null ? "null" : typeof(callback)));
        mpoTyroEftpos._callback = callback;
        if (src === 'commencePayment') {
            mpoTyroEftpos.setTxnCallback(src, callback);
        }
    },

    setTxnCallback: function (src, callback = null) {
        logger('mpoTyroEftpos.setTxnCallback called from '+src+' '+(callback === null ? "null" : typeof(callback)));
        mpoTyroEftpos._txn_callback = callback;
    },

    returnResult: function(success, response, type, callback = null) {
        logger('mpoTyroEftpos.returnResult called: '+type+' - '+success);
        logger(JSON.stringify(response));
        mpoSentry.addInfoBreadcrumbAll('tyro','returnResult '+type+' '+success, response);
        //logger(callback);
        //logger(mpoTyroEftpos._callback);

        // callback = (callback !== undefined && callback !== null && typeof callback === "function") ? callback : mpoTyroEftpos._callback;
        // // do not clear the callback that a TXN will be relying upon
        // if (type !== TXN_STATUS && type !== TXN_STATUS_INFLIGHT && type !== TOKEN) {
        //     mpoTyroEftpos.setCallback('returnResult');
        // }

        // failover to help ensure a callback is always called for transaction results
        // if (type === TXN && callback === null) {
        //     logger("No callback specified for "+type + " - using txn_callback");
        //     mpoSentry.captureWarnMessageAll("No callback specified for "+type + " - using txn_callback");
        //     callback = mpoTyroEftpos._txn_callback;
        //     mpoTyroEftpos._txn_callback = null;
        // }

        // separate callback for TXN so there is no conflict
        if (type === TXN) {
            callback = (callback !== undefined && callback !== null && typeof callback === "function") ? callback : mpoTyroEftpos._txn_callback;
            mpoTyroEftpos.setTxnCallback('returnResult');
        } else {
            callback = (callback !== undefined && callback !== null && typeof callback === "function") ? callback : mpoTyroEftpos._callback;
            // if (type !== TXN_STATUS_INFLIGHT) {
            //     mpoTyroEftpos.setCallback('returnResult');
            // }
        }

        if (callback !== undefined && callback !== null && typeof(callback) === 'function') {
            callback(success, 0, response);
            // success at this point means non-error response received from tyro, still need to check contents of response for whether the action was actually successful
            /*
            if (success) {
                const status_code = mpoTyroEftpos.getStatusCodeFromResponse(response);

                let data = "";
                //let action_success = false;
                if (response.hasOwnProperty('data') && response.data !== undefined && response.data !== null) {
                    data = response.data;
                    // if (data.hasOwnProperty('response') && data.response !== undefined &&
                    //     data.response.hasOwnProperty('success') && data.response.success !== undefined) {
                    //     action_success = data.response.success === true;
                    // }
                    if (typeof data === "object" && (type === TXN || type === TXN_STATUS || type === TXN_STATUS_INFLIGHT)) {
                        if (data.hasOwnProperty('sessionId') === false) {
                            data['sessionId'] = mpoTyroEftpos._last_session_id;
                        }
                        data['posGuid'] = mpoTyroEftpos._pos_guid;
                    }
                }

                if (type !== TXN_STATUS_INFLIGHT) {
                    if (type === TXN_STATUS) {
                        callback(success, status_code, data, mpoTyroEftpos._compare_transaction);
                    } else {
                        callback(success, status_code, data);
                    }
                }
            } else {
                // response is error text
                logger("Error response for "+type+": "+JSON.stringify(response));
                mpoSentry.captureWarnMessageAll("Error response for "+type+": "+JSON.stringify(response));
                if (type !== TXN_STATUS_INFLIGHT) {
                    callback(success, 0, response);
                }
            }
            */
        } else {
            logger("No callback specified for "+type+": "+JSON.stringify(response));
            mpoSentry.captureWarnMessageAll("No callback specified for "+type+": "+JSON.stringify(response));

            // if (type === TXN) {
            //     const txnInProgress = mpoTyroEftpos.getTransactionCookie();
            //     if (txnInProgress && txnInProgress !== undefined && txnInProgress !== null && typeof txnInProgress === 'object') {
            //         mpoTyroEftpos.getTransactionStatus(txnInProgress, mpoTyroEftpos._txn_callback);
            //     }
            // }
        }
    },

    sendPairRequest: function (params, callback = null) {
        logger("mpoTyroEftpos.sendPairRequest called");
        logger(JSON.stringify(params));
        mpoSentry.addBreadcrumb('tyro','sendPairRequest','info');
        mpoTyroEftpos._paired = false;
        mpoTyroEftpos._iclient.pairTerminal(params.username, params.paircode, (response) => {
            if (response.status === "success") {
                // {"message":"Pairing successful.","status":"success","integrationKey":"5fb17e568779dfe4da5b01065c44a289"}
                mpoTyroEftpos._mid = params.username;
                mpoTyroEftpos._tid = params.paircode;
                mpoTyroEftpos._integration_key = response.integrationKey;
                mpoTyroEftpos._paired = true;
                //set cookie, so that if the page is refreshed, the pairing is still active
                mpoTyroEftpos.setPairingCookie();
                mpoTyroEftpos.updateBackendSettings();
                mpoTyroEftpos.returnResult(true, response, PAIR, callback);
            } else {
                mpoTyroEftpos.returnResult(false, response, PAIR, callback);
            }
        });
    },

    setPairingCookie: function () {
        logger('mpoTyroEftpos.setPairingCookie called');
        const cookies = new Cookies();
        const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
        const pair = {
            pos: mpoTyroEftpos._pos_guid,
            mid: mpoTyroEftpos._mid,
            tid: mpoTyroEftpos._tid,
            key: mpoTyroEftpos._integration_key,
        }
        logger(pairingCookieName + ": " + JSON.stringify(pair));
        mpoSentry.addInfoBreadcrumbAll('tyro','setTransactionCookie', pair);
        cookies.set(pairingCookieName, JSON.stringify(pair), {maxAge: maxAge});
        return true;
    },

    getPairingCookie: function () {
        logger('mpoTyroEftpos.getPairingCookie called');
        const cookies = new Cookies();
        const pair = cookies.get(pairingCookieName);
        logger(pair ? JSON.stringify(pair) : "No pairing cookie");
        if (pair) {
            mpoSentry.addInfoBreadcrumbAll('tyro','getPairingCookie', pair);
            mpoTyroEftpos._pos_guid = pair.pos;
            mpoTyroEftpos._mid = pair.mid;
            mpoTyroEftpos._tid = pair.tid;
            mpoTyroEftpos._integration_key = pair.key;
            mpoTyroEftpos._available = mpoTyroEftpos._pos_guid !== ""
        }
        mpoTyroEftpos._paired = mpoTyroEftpos._available && mpoTyroEftpos._integration_key !== ""
        return mpoTyroEftpos._paired;
    },

    setTransactionCookie: function (request) {
        logger('mpoTyroEftpos.setTransactionCookie called');
        const cookies = new Cookies();
        const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
        request['sessionId'] = mpoTyroEftpos._last_session_id;
        request['posGuid'] = mpoTyroEftpos._pos_guid;
        logger(transactionCookieName + ": " + JSON.stringify(request));
        mpoSentry.addInfoBreadcrumbAll('tyro','setTransactionCookie', request);
        cookies.set(transactionCookieName, JSON.stringify(request), {maxAge: maxAge});
        return true;
    },

    getTransactionCookie: function () {
        logger('mpoTyroEftpos.getTransactionCookie called');
        const cookies = new Cookies();
        const request = cookies.get(transactionCookieName);
        logger(request ? JSON.stringify(request) : "No transaction cookie");
        if (request) {
            mpoSentry.addInfoBreadcrumbAll('tyro','getTransactionCookie', request);
            return request; //object
        }
        return null;
    },

    commencePayment: function (tender_amount, total_amount, desc, callback) {
        logger("mpoTyroEftpos.commencePayment called");
        mpoSentry.addBreadcrumb('tyro','commencePayment','info');
        if (mpoTyroEftpos.isTyroEftposAvailable()) {
            if (mpoTyroEftpos.isPaired()) {
                // logger("tender_amount: "+tender_amount);
                // logger("tender_amount*100: "+parseInt(Math.round(tender_amount*100),10));
                if (tender_amount > 0 && total_amount > 0 && tender_amount <= total_amount) {
                    //mpoTyroEftpos._tender_amount = parseInt(parseFloat(tender_amount*100),10);
                    mpoTyroEftpos._tender_amount = parseInt(Math.round(tender_amount*100), 10);
                    while (mpoTyroEftpos._tender_amount/100 < tender_amount) {
                        // logger("mpoTyroEftpos._tender_amount: "+mpoTyroEftpos._tender_amount);
                        mpoTyroEftpos._tender_amount++;
                    }
                    // logger("mpoTyroEftpos._tender_amount: "+mpoTyroEftpos._tender_amount);
                    // if (!isProduction()) mpoTyroEftpos.returnResult(false, 'JUST TESTING', TXN, callback);

                    //mpoTyroEftpos._total_amount = parseInt(parseFloat(total_amount*100),10);
                    mpoTyroEftpos._total_amount = parseInt(Math.round(total_amount*100),10);
                    while (mpoTyroEftpos._total_amount/100 < total_amount) {
                        // logger("mpoTyroEftpos._total_amount: "+mpoTyroEftpos._total_amount);
                        mpoTyroEftpos._total_amount++;
                    }
                    // logger("mpoTyroEftpos._total_amount: "+mpoTyroEftpos._total_amount);

                    // mpoTyroEftpos._last_transaction_ref = mpoTyroEftpos.generateRef();
                    // mpoTyroEftpos.setTxnCallback('commencePayment', callback);

                    const request = {
                        amount: mpoTyroEftpos._tender_amount.toString(),
                        cashout: "0",
                        integratedReceipt: mpoTyroEftpos._integrated_receipts,
                        enableSurcharge: mpoTyroEftpos._integrated_surcharging,
                        mid: mpoTyroEftpos._mid,
                        tid: mpoTyroEftpos._tid,
                        integrationKey: mpoTyroEftpos._integration_key,
                    };

                    mpoTyroEftpos._iclient.initiatePurchase(request, {
                        receiptCallback: mpoTyroEftpos.receiptCallbackImpl,
                        transactionCompleteCallback: callback
                    });
                } else {
                    //alert('Invalid tender amount (MSG-TCP3)');
                    mpoTyroEftpos.returnResult(false, 'Invalid tender amount (MSG-TCP3)', TXN, callback);
                }
            } else {
                //alert('Eftpos (Tyro) is not paired (MSG-TCP2). Contact a member of staff for assistance.');
                mpoTyroEftpos.returnResult(false, 'Eftpos (Tyro) is not paired (MSG-TCP2). Contact a member of staff for assistance.', TXN, callback);
            }
        } else {
            //alert('Eftpos (Tyro) is not currently available (MSG-TCP1). Contact a member of staff for assistance.');
            mpoTyroEftpos.returnResult(false, 'Eftpos (Tyro) is not currently available (MSG-TCP1). Contact a member of staff for assistance.', TXN, callback);
        }
    },

    receiptCallbackImpl: function (receipt) {
        logger("Receipt = " + JSON.stringify(receipt));
    },
    transactionCallbackImpl: function (response) {
        logger("Transaction = " + JSON.stringify(response));
    },

    commenceRefund: function (amount, callback = null) {
        logger("mpoTyroEftpos.commenceRefund called");
        mpoSentry.addBreadcrumb('tyro','commenceRefund','info');
        if (mpoTyroEftpos.isTyroEftposAvailable()) {
            if (mpoTyroEftpos.isPaired()) {
                let amountInt = parseInt(Math.round(amount*100), 10);
                while (amountInt/100 < amount) {
                    amountInt++;
                }
                const request = {
                    amount: amountInt.toString(),
                    integratedReceipt: mpoTyroEftpos._integrated_receipts,
                    enableSurcharge: mpoTyroEftpos._integrated_surcharging,
                    mid: mpoTyroEftpos._mid,
                    tid: mpoTyroEftpos._tid,
                    integrationKey: mpoTyroEftpos._integration_key,
                };

                mpoTyroEftpos._iclient.initiateRefund(request, {
                    receiptCallback: mpoTyroEftpos.receiptCallbackImpl,
                    transactionCompleteCallback: callback
                });
            } else {
                //alert('Eftpos (Tyro) is not paired (MSG-TCR2). Contact a member of staff for assistance.');
                mpoTyroEftpos.returnResult(false, 'Eftpos (Tyro) is not paired (MSG-TCR2). Contact a member of staff for assistance.', REFUND, callback);
            }
        } else {
            //alert('Eftpos (Tyro) is not currently available (MSG-TCR1). Contact a member of staff for assistance.');
            mpoTyroEftpos.returnResult(false, 'Eftpos (Tyro) is not currently available (MSG-TCR1). Contact a member of staff for assistance.', REFUND, callback);
        }
    }

}