import mobiscroll from "@mobiscroll/react";
import queryString from "query-string";

const appEnv = process.env.REACT_APP_APP_ENV !== undefined && process.env.REACT_APP_APP_ENV !== '' ? process.env.REACT_APP_APP_ENV : 'PROD'; // DEV, STG, PROD
const appMode = process.env.REACT_APP_APP_MODE !== undefined && process.env.REACT_APP_APP_DEBUG !== '' ? process.env.REACT_APP_APP_MODE : 'PERSONAL'; // PERSONAL, KIOSK, POS
const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

export var logOutput = '';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const logger = (str, force) => {
    if (typeof console === "object") {
        force = force || false;
        if (typeof str === "object") {
            try {
                console.log(JSON.stringify(str));
            } catch (err) {
                console.log(str);
            }
        } else {
            console.log(str);
        }
    }
    if (isDebugMode()) {
        if (typeof str === "object") {
            try {
                logOutput += "\n" + unixUtcTimeSec() + ": " + JSON.stringify(str);
            } catch (err) {
                logOutput += "\n" + unixUtcTimeSec() + ": " + str;
            }
        } else {
            logOutput += "\n" + unixUtcTimeSec() + ": " + str;
        }
        /*
        if (typeof str === "object") {
            for (let s in str) {
                logOutput += "\n - " + s+'='+str[s];
                if (typeof str[s] === "object") {
                    for (let o in str[s]) {
                        logOutput += "\n -- " + o+'='+str[s][o];
                    }
                }
            }
        }
        */
    }
}

export const isDebugMode = () => {
    return debugMode;
}

export const getAppMode = () => {
    return appMode;
}
export const isAppModePersonal = () => {
    //return appMode === 'PERSONAL';
    return !isAppModeKiosk() && !isAppModePos();
}
export const isAppModeKiosk = () => {
    return appMode === 'KIOSK';
}
export const isAppModePos = () => {
    return appMode === 'POS';
}

export const clearLogOutput = () => {
    logOutput = unixUtcTimeSec() + ": log cleared";
}

export const nl2br = (str) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
}

export const getPopupResponsiveSettings = (isIos) => {
    return {
        xsmall: {
            display: isIos ? "center" : "top"
        },
        small: {
            display: "center"
        }
    }
}

export const isProduction = () => {
    return appEnv === 'PROD'; // process.env.NODE_ENV === 'production';
}

export const isCordova = () => {
    /*eslint-disable no-undef*/
    return (typeof cordova === 'object');
    /*eslint-enable no-undef*/
}

export const isGoogleMapsLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return (typeof google === 'object' && typeof google.maps === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const isTyroEftposClientLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        logger("isTyroEftposClientLoaded: "+(typeof TYRO));
        logger("isTyroEftposClientLoaded iclient: "+(typeof TYRO.IClientWithUI));
        return (typeof TYRO === 'object' && typeof TYRO.IClientWithUI === 'function');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}
export const isAppleSigninLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return isCordova() && (typeof window.cordova.plugins === 'object' && typeof window.cordova.plugins.SignInWithApple === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const isGoogleSigninLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return isCordova() && (typeof window.plugins === 'object' && typeof window.plugins.googleplus === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const openWindow = (url, target, features, usewkwebview) => {
    if (usewkwebview) {
        /*
        // don't think this is needed anymore
        if (features !== '') {
            features += ',';
        }
        features += 'usewkwebview=yes';
         */
    }
    return window.open(url, target, features);
}

export const isIframe = () => {
    return !isCordova() && (window.top !== window.self);
}

export const attachDeviceInfoToData = (data) => {
    //attaches device info to the array that is passed in
    try {
        if (typeof (navigator) !== 'undefined' && 'userAgent' in navigator) {
            data.device_useragent = navigator.userAgent;
        }
        //logger("|attachDeviceInfoToData| device_useragent="+data.device_useragent);
        /*eslint-disable no-undef*/
        if (isCordova() && typeof device !== 'undefined') {
            //logger('DEVICE');
            //logger(device);
            var device_keys = ['cordova', 'platform', 'uuid', 'version', 'model'];
            for (var i = 0; i < device_keys.length; i++) {
                var key = device_keys[i];
                if (device[key]) {
                    var data_key = 'device_' + key;
                    data[data_key] = device[key];
                    //logger("|attachDeviceInfoToData| "+data_key+'='+device[key]);
                }
            }
        }
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
    }
}

export const unixDatetimeToDate = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // YYYY-MM-DD
        let date = jsdate.getFullYear()+'-'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'-'+`${jsdate.getDate()}`.padStart(2,0);
        return date;
    }
    return null;
}

export const unixDatetimeToDateAuEng = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // DD/MM/YYYY
        let date = `${jsdate.getDate()}`.padStart(2,0)+'/'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'/'+jsdate.getFullYear();
        return date;
    }
    return null;
}

export const unixDatetimeToTime = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // HH:mm
        let time = `${jsdate.getHours()}`.padStart(2,0)+':'+`${jsdate.getMinutes()}`.padStart(2,0);
        return time;
    }
    return null;
}

export const unixDatetimeToDateTime = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // YYYY-MM-DD HH:mm
        let date = jsdate.getFullYear()+'-'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'-'+`${jsdate.getDate()}`.padStart(2,0)+' '+`${jsdate.getHours()}`.padStart(2,0)+':'+`${jsdate.getMinutes()}`.padStart(2,0);
        return date;
    }
    return null;
}

export const unixUtcTimeSec = () => {
    return parseInt(new Date().getTime() / 1000, 10);
}

export const unixUtcTimeMs = () => {
    return parseInt(new Date().getTime(), 10);
}

export const unixUtcTimeOffsetMins = (offsetMins) => {
    let t = unixUtcTimeSec();
    t = t + (offsetMins*60);
    return t;
}

export const jsDateToday = () => {
    let d = new Date();
    d.setSeconds(0);
    d.setHours(0);
    d.setMinutes(0);
    d.setMilliseconds(0);
    return d;
}

export const jsDateTomorrow = () => {
    let d = jsDateToday();
    d.setDate(d.getDate() + 1);
    return d;
}

export const parseUrlSegments = (url) => {
    const obj = {
        table_num: null,
        promo_code: null,
        booking_name: null,
        booking_ref: null,
        booking_pax: null,
    }

    try {
        // Check if it's a valid URL string
        new URL(url);
        logger('Valid URL ' + url);

        // check query string first
        if (url.indexOf('?') > -1 && url.indexOf('app.link') === -1) {
            // direct url or firebase url (and NOT branch.io)
            const query = url.substring(url.lastIndexOf("?"));
            logger('Query string ' + query);
            const params = queryString.parse(query);
            obj.table_num = params.table ? params.table : null;
            obj.promo_code = params.promo ? params.promo : null;
            obj.booking_name = params.bookingname ? params.bookingname : null;
            obj.booking_ref = params.bookingref ? params.bookingref : null;
            obj.booking_pax = params.bookingpax ? params.bookingpax : null;
        } else {
            // assume branch.io url, e.g. https://mpo.app.link/teddy-pickers-tbl20
            // Get everything after the last slash
            let lastSegment = url.split('/').pop();
            logger('Last segment ' + lastSegment);
            if (lastSegment.indexOf('?') > -1) {
                // ignore any query params
                lastSegment = lastSegment.split('?')[0];
            }

            // If there's no content after the slash, return empty array
            if (!lastSegment) {
                return obj;
            }

            // Split by hyphen and filter out empty strings
            const segments = lastSegment.split('-').filter(segment => segment.length > 0);
            if (segments.length === 0) {
                return obj;
            }
            logger(segments)

            for (let i = 0; i < segments.length; i++) {
                if (segments[i].substring(0,3) === 'tbl') {
                    obj.table_num = segments[i].substring(3);
                    continue;
                }
                if (segments[i].substring(0,3) === 'prm') {
                    obj.promo_code = segments[i].substring(3);
                    continue;
                }
                if (segments[i].substring(0,3) === 'bkn') {
                    obj.booking_name = segments[i].substring(3);
                    continue;
                }
                if (segments[i].substring(0,3) === 'bkr') {
                    obj.booking_ref = segments[i].substring(3);
                    continue;
                }
                if (segments[i].substring(0,3) === 'bkp') {
                    obj.booking_pax = segments[i].substring(3);
                }
            }
        }
        logger(obj);
        return obj;
    } catch (e) {
        logger('Invalid URL ' + url);
        return obj;
    }
}