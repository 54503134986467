import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from '../../lib/Sentry';
import * as actions from "../../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {attachDeviceInfoToData, isAppModeKiosk, isCordova, logger} from "../../shared/utility";
import {mpoOneSignal} from "../../lib/OneSignal";
import axiosInstance from "../../components/axios";
import * as Sentry from "@sentry/react";
import {mpoTyroEftpos} from "../../lib/TyroEftpos";
import OrderItem from "../../components/merchant/OrderItem";

class PosAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            authAttempts: 0,
            authed: false,
            pass: '',
            passValid: true,
            passError: '',
            nav: '',
            orderId: '',
            refund: '',
        };
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','PosAdmin','info');
    }

    onPasswordChange = (event) => {
        const invalid = event.target.value === '' ? 'This field is required' : false;
        this.setState({
            pass: event.target.value,
            passValid: !this.state.submitted || !invalid,
            passError: invalid || ''
        });
    }

    auth = (event) => {
        event.preventDefault();

        if (this.state.pass === '') {
            return;
        }

        let attempts = this.state.authAttempts + 1;
        this.setState({
            submitting: true,
            authAttempts: attempts,
        });

        const data = {
            RequestAction: 'Login',
            method: 'email',
            email: this.props.user.customer.email,
            password: this.state.pass,
        };
        mpoSentry.addBreadcrumb('action',data.RequestAction,'info');

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    let orderId = '';
                    let refundAmount = '';
                    if (this.hasOrderHistory()) {
                        orderId = this.props.merchant.history[0].id;
                        refundAmount = this.props.merchant.history[0].total;
                    }
                    this.setState({
                        submitting: false,
                        authed: true,
                        orderId: orderId,
                        refund: refundAmount,
                    });
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    if (this.state.authAttempts >= 3) {
                        this.props.history.push('/logout');
                    } else {
                        this.setState({
                            submitting: true,
                            authed: false,
                        });
                    }
                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error PA1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    refund = (event) => {
        event.preventDefault();
        if (this.state.orderId === '') {
            mobiscroll.toast({message: 'Enter Order ID', color: 'danger'});
            return;
        }
        if (this.state.refund === '') {
            mobiscroll.toast({message: 'Refund Amount', color: 'danger'});
            return;
        }
        let amount = parseFloat(this.state.refund).toFixed(2);
        if (isNaN(amount) || amount <= 0) {
            mobiscroll.toast({message: 'Invalid Refund Amount', color: 'danger'});
            return;
        }

        mobiscroll.confirm({
            title: 'Refund',
            message: `Are you sure you want to refund $${amount}?`,
            okText: 'Refund',
            cancelText: 'Cancel'
        }).then((res) => {
            if (res) {
                if (this.props.user.eftpos.eftpos_provider === "LINKLY") {
                } else if (this.props.user.eftpos.eftpos_provider === "TYRO") {
                    this.setState({
                        submitting: true,
                    });

                    this._reinit_tyro(); // re-init if needed
                    if (!mpoTyroEftpos.isTyroEftposAvailable()) {
                        mobiscroll.toast({message: 'Eftpos not available', color: 'danger'});
                        return;
                    }
                    mpoTyroEftpos.commenceRefund(amount, this._tyro_refund_callback);
                }
            }
        });
    }

    _tyro_refund_callback = (response) => {
        logger(response);
        // {"result":"APPROVED","transactionId":"8eebaa1baf44de46e9c875f4f2a97bfcf256","cardType":"EFTPOS","transactionReference":"157918","authorisationCode":"125441","issuerActionCode":"00","elidedPan":"XXXXXXXXXXX5413","rrn":"241111157918","baseAmount":"100.00","transactionAmount":"100.00"}

        const result = response.result;
        if (result === 'APPROVED') {
            mobiscroll.toast({message: 'Refund successful', color: 'success'});
            this.setState({
                submitting: false,
                orderId: '',
                refund: '',
            });
        } else {
            mobiscroll.toast({message: 'Refund failed: ' + result, color: 'danger'});
            this.setState({
                submitting: false,
            });
        }
    }

    _reinit_tyro = () => {

        // refresh page wipes mpoTyroEftpos settings, so re-init iclient
        if (!mpoTyroEftpos.isTyroEftposAvailable() && this.props.user.hasOwnProperty('eftpos') && this.props.user.eftpos !== undefined &&
            this.props.user.eftpos.hasOwnProperty('pos_guid') && this.props.user.eftpos.pos_guid !== undefined && this.props.user.eftpos.pos_guid !== "" && this.props.user.eftpos.eftpos_provider === "TYRO") {

            // re-init tyro eftpos
            logger('Checkout: re-init tyro');
            mpoTyroEftpos.Init(this.props.user.eftpos.pos_guid,
                this.props.user.eftpos.api_key,
                this.props.user.eftpos.username,
                this.props.user.eftpos.secret,
                this.props.user.eftpos.integrated_receipts,
                this.props.user.eftpos.integrated_receipts_merchant_copy,
                this.props.user.eftpos.integrated_surcharging,
                // this.props.user.customer.id, this.props.user.customer.name,
                'AU', 'AUD');

            // recover pairing if possible
            if (!mpoTyroEftpos.isPaired()) {
                mpoTyroEftpos.getPairingCookie();
            }
        }

    }

    hasOrderHistory = () => {
        return this.props.merchant !== undefined && this.props.merchant.hasOwnProperty('history') &&
            this.props.merchant.history !== undefined && this.props.merchant.history.length > 0;
    }

    render = () => {
        /*
        const historyJSX = this.hasOrderHistory() ? this.props.merchant.history.map((order) => {
                return <OrderItem key={order.id} item={order} merchantId={this.props.merchant.id} menuId={this.props.merchant.menu_id} />
            }) : null;

        logger(historyJSX);
        logger(this.hasOrderHistory());
        logger(this.props.merchant.history);
         */

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`POS Admin - ${process.env.REACT_APP_APP_TITLE}`}</title>
                </Helmet>
                {this.state.authed ?
                    <mobiscroll.Form className="mpo-form-width-md">
                        {this.state.nav === '' ?
                            <mobiscroll.FormGroup className="mbsc-padding">
                                <h3>Admin</h3>
                                <mobiscroll.Button onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        nav: 'refund',
                                    });
                                }} color="primary" block={true}>Perform Refund
                                </mobiscroll.Button>
                                <mobiscroll.Button block={true} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push('/logout')
                                }}>Logout of {isAppModeKiosk() ? 'Kiosk' : 'POS'}</mobiscroll.Button>
                                <mobiscroll.Button block={true} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push('/')
                                }}>Exit Admin</mobiscroll.Button>
                            </mobiscroll.FormGroup>
                            :
                            <mobiscroll.FormGroup className="mbsc-padding">
                                <h3>Refund</h3>
                                <mobiscroll.Input
                                    labelStyle="stacked"
                                    type="text"
                                    value={this.state.orderId}
                                    onChange={(e) => {
                                        this.setState({
                                            orderId: e.target.value,
                                        });
                                    }}
                                    placeholder=""
                                >Order ID
                                </mobiscroll.Input>
                                <mobiscroll.Input
                                    labelStyle="stacked"
                                    type="number"
                                    value={this.state.refund}
                                    onChange={(e) => {
                                        this.setState({
                                            refund: e.target.value,
                                        });
                                    }}
                                    placeholder="0.00"
                                >Refund Amount
                                </mobiscroll.Input>
                                <mobiscroll.Button onClick={this.refund} color="primary" block={true} disabled={this.state.submitting}>Refund
                                </mobiscroll.Button>
                                <mobiscroll.Button onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        nav: '',
                                    });
                                }} block={true}>Back
                                </mobiscroll.Button>
                            </mobiscroll.FormGroup>
                        }
                    </mobiscroll.Form>
                    :
                    <mobiscroll.Form className="mpo-form-width-md" onSubmit={this.auth}>
                        <mobiscroll.FormGroup className="mbsc-padding">
                            <mobiscroll.Input
                                labelStyle="stacked"
                                type="password"
                                passwordToggle={true}
                                value={this.state.pass}
                                onChange={this.onPasswordChange}
                                valid={this.state.passValid}
                                errorMessage={this.state.passError}
                                autoComplete="off"
                            >Password
                            </mobiscroll.Input>

                            <mobiscroll.Button type="submit" color="primary"
                                               block={true}
                                               disabled={this.state.submitting}>OK
                            </mobiscroll.Button>
                        </mobiscroll.FormGroup>
                    </mobiscroll.Form>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        merchant: state.menu.merchant,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // updateStateWithCustomer: (customer, ownProps) => {
        //     const redirect = '/account/orders';
        //     dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        // }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PosAdmin));
